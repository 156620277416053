import React from 'react';
import './style.css'

const MusioLogo = (props) => {
    let scale = props.scale || 100;
    return (
<svg width={scale} height={scale} viewBox="0 0 320 320" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M250.2 184.4L189.5 61.9C181.1 45 188.1 24.4 205 16C213.2 11.9 222.5 11.3 231.2 14.3C239.9 17.2 246.9 23.3 251 31.5L311.6 154C320 170.9 313 191.5 296.1 199.9C291.2 202.3 286.1 203.5 280.9 203.5C268.4 203.5 256.2 196.5 250.2 184.4Z" fill="url(#paint0_linear_4748_430)"/>
    <path d="M144.6 199.9C127.7 191.5 120.7 170.9 129.1 154L189.8 31.5C193.9 23.3 200.9 17.2 209.6 14.3C218.3 11.4 227.6 12 235.8 16C243.9 20 250 27.1 253 35.8C255.9 44.5 255.3 53.8 251.3 62L190.6 184.5C184.6 196.6 172.5 203.6 159.8 203.6C154.7 203.4 149.5 202.3 144.6 199.9Z" fill="url(#paint1_linear_4748_430)"/>
    <path d="M23.9 199.9C6.9 191.5 7.15256e-07 170.9 8.4 154L69.1 31.5C73.2 23.3 80.2 17.2 88.9 14.3C97.6 11.4 106.9 12 115.1 16C132 24.4 139 45 130.6 61.9L69.8 184.4C63.8 196.5 51.7 203.5 39 203.5C33.9 203.4 28.7 202.3 23.9 199.9Z" fill="url(#paint2_linear_4748_430)"/>
    <path d="M51.9 241.7C50.1 241.7 49.2 242.9 48.8 243.4L29.6 267.8L10.3 243.4C9.8 242.8 9 241.7 7.2 241.7C5.3 241.7 4 243.1 4 245V288.3C4 290.5 5.5 292 7.8 292H9.1C11.3 292 12.9 290.5 12.9 288.3V261.1L26.8 278.4C28.3 280.3 30.9 280.3 32.5 278.4L46.3 261.1V288.3C46.3 290.5 47.8 292 50.1 292H51.4C53.6 292 55.2 290.5 55.2 288.3V245C55 242.9 53.4 241.7 51.9 241.7Z" fill="white"/>
    <path d="M123.5 241.7H122.3C120.1 241.7 118.5 243.2 118.5 245.4V271.2C118.5 283.9 108.1 284.6 106 284.6C103.9 284.6 93.4 284 93.4 271.2V245.4C93.4 243.2 91.9 241.7 89.6 241.7H88.3C86.1 241.7 84.5 243.2 84.5 245.4V271.8C84.5 284.4 93.1 292.9 105.9 292.9C118.7 292.9 127.1 284.4 127.1 271.8V245.4C127.3 243.3 125.7 241.7 123.5 241.7Z" fill="white"/>
    <path d="M178.6 262.8C177.9 262.7 177.2 262.6 176.5 262.4C169.8 261.2 164.2 260 164.2 256.1C164.2 251.2 170.4 249.2 173.7 249.2C177.5 249.2 180.9 250.1 184.7 252.3C186.5 253.3 188.6 252.8 189.7 251L190.3 250.1C190.9 249.2 191.1 248.1 190.8 247.1C190.6 246.2 189.9 245.3 189.1 244.9C184.5 242.2 179.6 240.8 174.2 240.8C164.9 240.8 155.5 245.6 155.5 256.2C155.5 266.9 167.3 269.4 173 270.4L174.3 270.6C182.7 272.2 186.3 273.6 186.3 277.6C186.3 283.8 179.2 284.5 176.1 284.5C171.6 284.5 167.1 283.1 163 280.5C161.1 279.3 159 279.8 157.8 281.6L157.1 282.6C156.4 283.7 156.4 284.8 156.5 285.5C156.6 286.2 157.1 287.1 158.2 287.8C163.5 291.2 169.2 292.8 176.2 292.8C187.8 292.8 195 287 195 277.6C194.9 265.9 183 263.7 178.6 262.8Z" fill="white"/>
    <path d="M230.5 241.7H229.2C227 241.7 225.4 243.2 225.4 245.4V288.3C225.4 290.5 226.9 292 229.2 292H230.5C232.7 292 234.3 290.5 234.3 288.3V245.4C234.3 243.3 232.7 241.7 230.5 241.7Z" fill="white"/>
    <path d="M290.1 240.7C275.7 240.7 264.4 252.2 264.4 266.9C264.4 281.6 275.7 293.1 290.1 293.1C304.5 293.1 316 281.9 316 266.9C316 251.9 304.6 240.7 290.1 240.7ZM290.1 249.1C299.3 249.1 307.2 257.3 307.2 266.9C307.2 276.5 299.4 284.7 290.1 284.7C280.8 284.7 273.2 276.5 273.2 266.9C273.2 257.3 280.9 249.1 290.1 249.1Z" fill="white"/>
    <defs>
    <linearGradient id="paint0_linear_4748_430" x1="198.741" y1="32.869" x2="262.181" y2="124.729" gradientUnits="userSpaceOnUse">
    <stop offset="0.09" stopColor="#FB2545"/>
    <stop offset="1" stopColor="#AB2240"/>
    </linearGradient>
    <linearGradient id="paint1_linear_4748_430" x1="236.136" y1="19.2032" x2="146.726" y2="192.043" gradientUnits="userSpaceOnUse">
    <stop stopColor="#FB2545"/>
    <stop offset="1" stopColor="#AB2240"/>
    </linearGradient>
    <linearGradient id="paint2_linear_4748_430" x1="116.45" y1="16.2453" x2="24.2705" y2="196.105" gradientUnits="userSpaceOnUse">
    <stop stopColor="#FB2545"/>
    <stop offset="1" stopColor="#AB2240"/>
    </linearGradient>
    </defs>
</svg>
    );
};

export default MusioLogo;
