import React from 'react';
import {Link} from 'react-router-dom'

import './style.css';
import MusioLogo from 'components/MusioLogo';
import MusioButton from 'components/MusioButton';


const Error = (props) => {
  return (
    <div className='onboarding-container'>
        <MusioLogo />
        <div className="onboarding-spacer" />

        <h1 className='onboarding-header'>oh no!.. looks like something went wrong</h1>
        <div className="onboarding-spacer" />

        <Link to="/login">
          <MusioButton text="go back." />
        </Link>
      </div>
  );
}

export default Error;
