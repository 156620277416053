import React, { useState, useEffect } from "react";
import axios from "axios";

import "./style.css";
import MusioButton from "components/MusioButton";
import MacOSIcon from "icons/MacOSIcon";
import WindowsIcon from "icons/WindowsIcon";

//NOTE: service response is a generic list of devices,
//      but this react component is hard-coded against 2 devices (macos, windows)

const MusioDownload = (props) => {
  const [isMacLoading, setIsMacLoading] = useState(false);
  const [isWindowsLoading, setIsWindowsLoading] = useState(false);

  const [appVersionData, setAppVersionData] = useState(null);
  const fetchAppVersions = () => {
    axios.get("api/v1/appVersions/latest").then((resp) => {
      if (resp.data.data.changeNotes) {
        setAppVersionData(resp.data.data);
      } else {
        setAppVersionData(null);
      }
    });
  };

  useEffect(() => {
    // mount
    fetchAppVersions();
  }, []);

  const buttonForDevice = (device, version) => {
    let button_text = (
      <span>
        {device.description}
        <br />
        {version}
      </span>
    );
    if (device.deviceType == "macos") {
      return (
        <MusioButton
          loading={isMacLoading}
          buttonType="squared"
          size="small"
          text={
            <div className="musio-download-item">
              {button_text}
              <MacOSIcon />
            </div>
          }
          onClick={(e) => {
            fetchDeviceUrl(device.downloadUrl, setIsMacLoading);
          }}
        />
      );
    } else if (device.deviceType == "windows") {
      return (
        <MusioButton
          loading={isWindowsLoading}
          buttonType="squared"
          size="small"
          text={
            <div className="musio-download-item">
              <span>{button_text}</span>
              <WindowsIcon />
            </div>
          }
          onClick={(e) => {
            fetchDeviceUrl(device.downloadUrl, setIsWindowsLoading);
          }}
        />
      );
    }
  };

  //TODO: is this the best practice?
  const downloadDeviceUrl = (url) => {
    var link = document.createElement("a");
    link.href = url;
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  //NOTE: appVersionData.devices[].downloadUrl is the endpoint to get the actual download url... it's confusing...
  //        HOWEVER it adds a layer of protection by delivering timed/signed urls of the actual installer
  const fetchDeviceUrl = (deviceUrl, stateToUpdate) => {
    stateToUpdate(true);
    axios
      .get(deviceUrl)
      .then((resp) => {
        // console.log(resp.data);
        downloadDeviceUrl(resp.data.url);
        stateToUpdate(false);
      })
      .catch((reason) => {
        // console.log(reason);
      });
  };

  return (
    <div className="musio-download-container">
      <div className="download-prompt-container">
        <p style={{ margin: 0, fontWeight: 700 }}>Download here:</p>
      </div>

      {appVersionData &&
        buttonForDevice(appVersionData.devices[0], appVersionData.version)}
      {appVersionData &&
        buttonForDevice(appVersionData.devices[1], appVersionData.version)}
    </div>
  );
};
export default MusioDownload;
