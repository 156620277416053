import axios from 'axios';
import React, {useEffect, useState } from 'react';

import './style.css';
import MusioLogo from 'components/MusioLogo';

const EmailChangeCancel = (props) => {

  const clearToken = (token) => {
    return axios.get('/api/v1/user/email/cancel', { params: {token: token} })
  }

  useEffect( () => { // mount
    //extract params
    const search = window.location.search;
    const params = new URLSearchParams(search);
    const token = params.get('token');
    if (token) {
      clearToken(token);
    }
    else {
        window.location.href='/error';
    }
  }, [] );


  return (
    <div className='onboarding-container'>
      <MusioLogo />
      <div className="onboarding-spacer" />

      <h1 className='onboarding-header'>email change request canceled.</h1>
    </div>
  );
};

export default EmailChangeCancel;
