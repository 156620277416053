//paste here!
import React from 'react';
import './style.css'

const LoadingSpinner = () => {
  return (
    <div className='loading'>
      <div className="lds-spinner">
        <div>
        </div>
        <div>
        </div>
        <div>
        </div>
        <div>
        </div>
        <div>
        </div>
        <div>
        </div>
        <div>
        </div>
        <div>
        </div>
        <div>
        </div>
        <div>
        </div>
        <div>
        </div>
        <div>
        </div>
      </div>
    </div>
  );
};

export default LoadingSpinner;