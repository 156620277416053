import React from 'react';
import './style.css'

const DashboardPanel = (props) => {
  let gap = props.gap || 0;
  let size = props.size || '';
  return (
    <div className={'info-panel ' + size}>
      <div className='info-header'>
        {props.icon}
        <hr className="info-header-hr" />
      </div>
      <div className="info-flex" style={{gap: gap}}>
        {props.children}
      </div>
    </div>
  );
};
  
export default DashboardPanel;