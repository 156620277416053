import React, { useState, useEffect } from 'react';
import { useSelector } from "react-redux";
import moment from 'moment'
import axios from 'axios';
import getSymbolFromCurrency from 'currency-symbol-map';
import { isLoaded, cancelRecurring,   fetchUserInfo, fetchBillingInfo, startStripeBillingSession } from 'store/resources';

import ErrorMessage from 'components/ErrorMessage';
import './style.css'


export const BillingDetails = (props) => {
  if (!isLoaded(props.billing) || !isLoaded(props.recurring)) {
    return null;
  }
  else if (props.billing.account == "" && props.recurring.status == "inactive") {
    return null; //<p className="subtletext">No Billing Info</p>
  }

  const hasActiveRecurring = props.recurring.status == 'active' || props.recurring.status == 'past_due';
  const hasUpcomingPayment = props.billing && props.recurring.canceled === false;

  const formatDate = () => {
    let renewalDate = moment(props.recurring.periodEnd, 'MMM DD YYYY').format('MMMM-DD-YYYY');
    renewalDate = renewalDate.replace(/(^|-)0+/g, "$1");
    renewalDate = renewalDate.split('-');
    return `${renewalDate[0]} ${renewalDate[1]}, ${renewalDate[2]}`;
  };

  if (hasActiveRecurring && hasUpcomingPayment) {
    const currencySymbol = getSymbolFromCurrency(props.recurring.currency.toUpperCase());
    return (
      <div className='upcoming-charge-container'>
        <h2>Upcoming Charges</h2>
        <h3 className="upcoming-charge-price">{currencySymbol}{(parseFloat(props.recurring.upcomingAmount || props.recurring.amount) / 100.0)}</h3>
        <p className="subtext">due {formatDate()}.</p><br />
        <p className='subtext' style={{marginBottom: '0.4rem'}}>
          {props.billing.card}
        </p>
        <a className="musio-link" href="#" onClick={startStripeBillingSession}>Manage Payments</a><br />
      </div>
    );
  }
  else if (hasActiveRecurring) {
    return (
      <div className='upcoming-charge-container'>
        <h3>No Upcoming Payments</h3>
        <div>&nbsp;</div>
        {props.recurring.canceled && 
        <p className='redtext centered'>your Musio membership is active <br/> through {formatDate()}.</p>
        }
      </div>
    );
  }

  return (
    <div style={{textAlign: 'center'}}>
      <p className="subtletext">No Upcoming Payments</p>
    </div>
  );
  


  return (
    <div className="billing-container">
      <UpcomingPaymentInfo recurring={props.recurring} billing={props.billing}/>
      <br />
      <div><h2>asdf</h2></div>

      <div className="cc-text">
        <h3>Payments</h3>
        <p className='subtext' style={{marginBottom: '0.4rem'}}>
          {props.billing.card}
        </p>
        <a className="musio-link" href="#" onClick={startStripeBillingSession}>Manage Payments</a>
      </div>
    </div>
  );
}

const UpcomingPaymentInfo = (props) => {
  const hasActiveRecurring = props.recurring.status == 'active';
  const hasUpcomingPayment = props.billing && props.recurring.canceled === false;

  const formatDate = () => {
    let renewalDate = moment(props.recurring.periodEnd, 'MMM DD YYYY').format('MMMM-DD-YYYY');
    renewalDate = renewalDate.replace(/(^|-)0+/g, "$1");
    renewalDate = renewalDate.split('-');
    return `${renewalDate[0]} ${renewalDate[1]}, ${renewalDate[2]}`;
  };

  if (hasActiveRecurring && hasUpcomingPayment) {
    const currencySymbol = getSymbolFromCurrency(props.recurring.currency.toUpperCase());
    return (
      <div className='upcoming-charge-container'>
        <h2>Upcoming Charges</h2>
        <h3 className="upcoming-charge-price">{currencySymbol}{(parseFloat(props.recurring.upcomingAmount || props.recurring.amount) / 100.0)}</h3>
        <p className="subtext">due {formatDate()}.</p>
        <p className='subtext' style={{marginBottom: '0.4rem'}}>
          {props.billing.card}
        </p>
      </div>
    );
  }
  else if (hasActiveRecurring) {
    return (
      <div className='upcoming-charge-container'>
        <h3>No Upcoming Payments</h3>
        <div>&nbsp;</div>
        {props.recurring.canceled && 
        <p className='redtext centered'>your Musio membership is active <br/> through {formatDate()}.</p>
        }
      </div>
    );
  }

  return (
    <div style={{textAlign: 'center'}}>
      <p className="subtletext">No Upcoming Payments</p>
    </div>
  );
};