import axios from "axios";
import { portalStore, actionSet } from "store";
import PubSub from "pubsub-js";

export const isLoaded = (data) => {
  return Object.keys(data).length >= 1;
};

export const markIsFetching = () => {
  portalStore.dispatch(actionSet("isFetching", true));
};

export const clearIsFetching = () => {
  portalStore.dispatch(actionSet("isFetching", false));
};

// user info
export const fetchUserInfo = () => {
  axios({ method: "GET", url: "/api/v1/user" })
    .then((resp) => {
      portalStore.dispatch(actionSet("contactName", resp.data.contactName));
      portalStore.dispatch(actionSet("email", resp.data.email));
      portalStore.dispatch(actionSet("userId", resp.data.userId));
    })
    .catch((err) => {});
};

export const clearUserInfo = () => {
  portalStore.dispatch(actionSet("contactName", ""));
  portalStore.dispatch(actionSet("email", ""));
};

// trial

export const fetchTrial = () => {
  return axios.get("/api/v1/user/trial").then((resp) => {
    portalStore.dispatch(actionSet("trial", resp.data));
  });
};

export const requestTrialRestart = (uuid) => {
  return axios.post("/api/v1/user/trial/restart", {uuid});
};

export const clearTrial = () => {
  portalStore.dispatch(actionSet("trial", {}));
};

// billing

export const startStripeBillingSession = () => {
  axios
    .post("/api/v1/user/billing")
    .then((resp) => {
      let sessionUrl = resp.data.sessionUrl;
      window.open(sessionUrl, "_blank");
    })
    .catch((err) => {});
};

export const getPaddlePaymentUpdateTxnId = () => {
  return axios
    .post("/api/v1/user/billing");
};

export const fetchBillingInfo = () => {
  return axios
    .get("/api/v1/user/billing")
    .then((resp) => {
      portalStore.dispatch(actionSet("billing", resp.data));
    })
    .catch((err) => {});
};

export const clearBillingInfo = () => {
  portalStore.dispatch(actionSet("billing", {}));
};

// recurring
export const startRecurring = (plan, referral = null, coupon = null) => {
  let post_data = { plan: plan };
  if (referral !== null) {
    post_data.referral = referral;
  }
  if (coupon !== null) {
    post_data.coupon = coupon;
  }

  return axios.post("/api/v1/user/recurring", post_data).then((resp) => {
    if (resp.data.checkoutSessionId) {
      const stripe = portalStore.getState().stripePromise;
      stripe.redirectToCheckout({ sessionId: resp.data.checkoutSessionId });
    } else {
      // PubSub.publish("RefreshMembership", true);
    }
  });
};

export const fetchRecurring = () => {
  return axios
    .get("api/v1/user/recurring")
    .then((resp) => {
      portalStore.dispatch(actionSet("recurring", resp.data));
    })
    .catch((err) => {});
};

export const fetchProration = (plan) => {
  return axios.post("/api/v1/user/recurring/prorate", {
    plan: plan,
  });
};

export const prorateRecurring = (plan) => {
  return axios.post("/api/v1/user/recurring/prorate", { plan });
};

// export const couponRetrieval = (plan) => {
//     // not sure which call to place here
//     return axios.post('/api/v1/user/recurring/prorate', {plan})
// };

export const changeRecurring = (plan, referral = null, coupon = null) => {
  console.log("changeRecurring");
  let post_data = { plan: plan };
  if (referral !== null) {
    post_data.referral = referral;
  }
  if (coupon !== null) {
    post_data.coupon = coupon;
  }
  return axios.post("/api/v1/user/recurring", post_data);
};

export const cancelRecurring = () => {
  return axios.post("/api/v1/user/recurring", {
    canceled: true,
  });
};

export const resumeRecurring = () => {
  return axios.post("/api/v1/user/recurring", {
    canceled: false,
  });
};

export const clearRecurring = () => {
  portalStore.dispatch(actionSet("recurring", {}));
};

// licenses

export const fetchLicenses = () => {
  return axios
    .get("/api/v1/user/licenses")
    .then((resp) => {
      portalStore.dispatch(actionSet("licenses", resp.data));
    })
    .catch((err) => {});
};

// fetch coupons
export const fetchCoupons = () => {
  return axios
    .get("/api/v1/user/coupons")
    .then((resp) => {
      portalStore.dispatch(actionSet("coupons", resp.data));
    })
    .catch((err) => {});
};

// export const purchaseLifetimeLicense = (referral=null, coupon=null) => {
//     let post_data = {}
//     if (referral !== null) {
//         post_data.referral = referral;
//     }
//     if (coupon !== null) {
//         post_data.coupon = coupon;
//     }
//     return axios.post('/api/v1/user/licenses/lifetime', post_data)
//     .then(resp=>{
//         if (resp.data.checkoutSessionId) {
//             const stripe = portalStore.getState().stripePromise;
//             stripe.redirectToCheckout({sessionId: resp.data.checkoutSessionId})
//         } else {
//             PubSub.publish('RefreshMembership', true);
//         }
//     });
// };

export const purchaseMusio1License = (referral = null, coupon = null) => {
  let post_data = {};
  if (referral !== null) {
    post_data.referral = referral;
  }
  if (coupon !== null) {
    post_data.coupon = coupon;
  }
  // return axios.post("/api/v1/user/licenses/musio1", post_data).then((resp) => {
  //   if (resp.data.checkoutSessionId) {
  //     const stripe = portalStore.getState().stripePromise;
  //     stripe.redirectToCheckout({ sessionId: resp.data.checkoutSessionId });
  //   } else {
  //     PubSub.publish("RefreshMembership", true);
  //   }
  // });
};


export const getLCDistribution = (id) => {
  return axios.get(`/api/v1/license-codes/distribution/${id}`);
}

export const createLCDistributionCode = (id, email) => {
  return axios.post(`/api/v1/license-codes/distribution/${id}`, {email});
}


export const clearLicenses = () => {
  portalStore.dispatch(actionSet("licenses", {}));
};

// clear all resources
export const clearResources = () => {
  clearTrial();
  clearRecurring();
  clearLicenses();
  clearBillingInfo();
};

export const planString = (plan) => {
  let plan_string = "";
  if (plan === "monthly") {
    plan_string = `Monthly`;
  } else if (plan === "annual") {
    plan_string = `Annual`;
  }
  return plan_string;
};

export const priceString = (plan) => {
  let plan_string = "";
  if (plan === "monthly") {
    plan_string = "monthly @ $9.99/mo";
  } else if (plan === "annual") {
    plan_string = "annual @ $99/yr";
  }
  return plan_string;
};
