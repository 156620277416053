import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import {
  markIsFetching,
  clearIsFetching,
  isLoaded,
  fetchTrial,
  clearTrial,
  fetchRecurring,
  clearRecurring,
  fetchLicenses,
  fetchCoupons,
  fetchBillingInfo,
  clearBillingInfo,
  clearLicenses,
} from "store/resources";
import PubSub from "pubsub-js";

import AppHeader from "./Header";
import UserPanel from "./UserPanel/index.js";
import MembershipPanel from "./MembershipPanel";
import PurchasePanel from "./PurchasePanel";
import SubscriptionPanel from "./SubscriptionPanel";
import MusioButton from "components/MusioButton";

import "./style.css";

const ActionBanner = (props) => {

  return (
    <div className="action-banner">
      {props.children}
    </div>
  )
};

const Dashboard = (props) => {
  const [showPI, setShowPI] = useState(false);
  const recurring = useSelector((state) => state.recurring);
  const isFetching = useSelector((state) => state.isFetching);
  const isLoading = !isLoaded(recurring)
  const [refreshTimeout, setRefreshTimeout] = useState(null);

  const refreshMembership = () => {
    if (isFetching) { return; }
    markIsFetching();

    clearRecurring();
    clearBillingInfo();
    clearTrial();
    clearLicenses();
    setTimeout(() => {
      Promise.all([
        fetchTrial(),
        fetchBillingInfo(),
        fetchRecurring(),
        fetchLicenses(),
        fetchCoupons(),
      ]);
      clearIsFetching();
    }, 2000);
  };

  const [subRefreshMembership, setSubRefreshMembership] = useState(null);

  const setShowPaymentInfo = (flag) => {
    setShowPI(flag);
  };

  useEffect(() => {
    // mount
    if (subRefreshMembership == null) {
      setSubRefreshMembership(PubSub.subscribe("RefreshMembership", (msg, data) => {
        refreshMembership();
      }));
    }
    refreshMembership();
  }, []);

  useEffect(
    () => () => {
      clearTimeout(refreshTimeout);
      // unmount
      PubSub.unsubscribe(subRefreshMembership);
    },
    []
  );
  const isStripe = recurring.isStripe;
  return (
    <div className="dashboard-container">
      { recurring && recurring.migration &&
        <a href={recurring.migration} style={{textDecoration: "none", color: "inherit"}}><ActionBanner><h2 style={{textDecoration: "underline"}}>Action Required! Click here to migrate your subscription to Paddle</h2></ActionBanner></a>
      }
      <AppHeader />
      <div className="dashboard-flex">
        <UserPanel />
        <MembershipPanel
          showPaymentInfo={showPI}
          setShowPaymentInfo={setShowPaymentInfo}
        />
        {(!isLoading && !isStripe) ?
           <SubscriptionPanel
            showPaymentInfo={showPI}
            setShowPaymentInfo={setShowPaymentInfo}
          />
          :
          <PurchasePanel
            showPaymentInfo={showPI}
            setShowPaymentInfo={setShowPaymentInfo}/>
        }
       
      </div>
    </div>
  );
};

export default Dashboard;
