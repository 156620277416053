import React from 'react';

import MusioLogo from 'components/MusioLogo';

import './style.css';



class SignUp extends React.Component {
  constructor(props) {
    super(props);
    //extract params
    const search = window.location.search;
    const params = new URLSearchParams(search);

    this.state = {
    };
  };

  componentDidMount(){
  }

  render() {
    return (
    <div className="onboarding-container">
        <MusioLogo />
        <div className="onboarding-spacer" />

        <h1 className="onboarding-header">Your account has been created!</h1>

        <p className="subtext">an account verification link has been sent to your email.</p>
    </div>
    );
  };
};

export default SignUp;
