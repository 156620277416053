import React from 'react';

export default function PaymentInfoIcon(){
    return(
<svg id='paymentInfoIcon' className='usericon' width="70" height="70" viewBox="0 0 100 100" fill="none" xmlns="http://www.w3.org/2000/svg">
    <rect x="27.1428" y="24.2302" width="56.8168" height="37.2112" rx="2" transform="rotate(15.4491 27.1428 24.2302)" stroke="#FB2645" strokeWidth="2"/>
    <rect x="60.5684" y="57.0029" width="8.36202" height="5.88168" rx="1" transform="rotate(15.4491 60.5684 57.0029)" fill="#FB2645"/>
    <rect x="30.082" y="38.5847" width="8.36202" height="3.17403" rx="0.3" transform="rotate(15.4491 30.082 38.5847)" fill="#FB2645"/>
    <rect x="41.0469" y="41.8149" width="8.36202" height="3.17403" rx="0.3" transform="rotate(15.4491 41.0469 41.8149)" fill="#FB2645"/>
    <rect x="52.0156" y="45.0442" width="8.36202" height="3.17403" rx="0.3" transform="rotate(15.4491 52.0156 45.0442)" fill="#FB2645"/>
    <rect x="62.9805" y="48.2749" width="8.36202" height="3.17403" rx="0.3" transform="rotate(15.4491 62.9805 48.2749)" fill="#FB2645"/>
    <circle cx="50" cy="50" r="48.5" stroke="#807D8E" strokeWidth="3"/>
</svg>
);
};