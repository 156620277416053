import React from "react";
import { logout } from "store/authentication";
import { useSelector } from "react-redux";

import "./style.css";
import MusioLogo from "components/MusioLogo";
import MusioDownload from "./MusioDownload";

import { isDesktopDevice } from "utils";

const Header = (props) => {
  let contactName = useSelector((state) => state.contactName) || "";
  const trial = useSelector((state) => state.trial);

  return (
    <header className="header-flex">
      <div className="header-logo">
        <MusioLogo scale="80" />
      </div>
      {isDesktopDevice() && (
        <div className="header-greeting">
          <h1 className="header-name">Hi, {contactName.split(" ")[0]}!</h1>
          <a
            id="logout-link"
            className="musio-link"
            onClick={() => logout()}
            href="#"
          >
            Log Out
          </a>
        </div>
      )}
      {!isDesktopDevice() && !trial.expired && (
        <div id="mobile-user-download-message">
          <h4>
            Congratulations! <br /> Your free trial has begun.
          </h4>
          <p>
            Visit this page on your desktop or laptop computer to download Musio
            and get started.
          </p>
        </div>
      )}

      {isDesktopDevice() && (
        <>
          <div className="header-spacer" />
          <MusioDownload />
        </>
      )}
    </header>
  );
};

export default Header;
