import React from 'react';

export default function PencilIcon () {
    return (
<svg id="pencil-svg" width="28" height="28" viewBox="0 0 28 28" fill="none" xmlns="http://www.w3.org/2000/svg">
    <rect x="6.60352" y="24.9052" width="4.96416" height="25.6705" transform="rotate(-135 6.60352 24.9052)" fill="#32303B"/>
    <rect x="25.4297" y="6.08069" width="4.96416" height="3.63519" transform="rotate(-135 25.4297 6.08069)" fill="#32303B"/>
    <path d="M0.754671 27.2453L2.21725 22.2725L5.72744 25.7827L0.754671 27.2453Z" fill="#32303B"/>
</svg>
    );
};