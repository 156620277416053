import React, { useState, useEffect } from "react";
import { connect, useSelector } from "react-redux";
import axios from "axios";
import PubSub from "pubsub-js";
import getSymbolFromCurrency from 'currency-symbol-map'

import LoadingSpinner from "components/LoadingSpinner";
import PaymentInfoIcon from "icons/PaymentInfoIcon";
import ErrorMessage from "components/ErrorMessage";

// import StartMembership from './StartMembership'
// import ActiveMembership from './ActiveMembership';
import DashboardPanel from "components/DashboardPanel";

import "./style.css";
import MusioButton from "components/MusioButton";
import { BillingDetails } from '../BillingDetails';


import {
  isLoaded,
  startRecurring,
  planString,
  priceString,
  cancelRecurring,
  resumeRecurring,
  changeRecurring,
  prorateRecurring,
  couponRetrieval,
  purchaseMusio1License,
} from "store/resources";

import {
  GetMusio1PriceId
} from "store/prices";
import { initializePaddle, Paddle } from '@paddle/paddle-js';

const PurchasePanel = (props) => {
  const [overlay, setOverlay] = useState("");
  const [prorateComponent, setProrateComponent] = useState("");
  const [couponComponent, setCouponComponent] = useState("");
  const recurring = useSelector((state) => state.recurring);
  const trial = useSelector((state) => state.trial);
  const licenses = useSelector((state) => state.licenses);
  const billing = useSelector((state) => state.billing);
  const coupons = useSelector((state) => state.coupons);
  const loading =
    !isLoaded(recurring) ||
    !isLoaded(trial) ||
    !isLoaded(licenses) ||
    !isLoaded(coupons) ||
    !isLoaded(billing);

  const activeRecurring = recurring ? recurring.status == "active" : false;
  const hasLicense =
    loading &&
    (licenses.active ||
      licenses.upcoming ||
      licenses.perpetual ||
      licenses.lifetime);
  const inTrial = trial.expired == false;

  const [referral, setReferral] = useState(null);
  const [rewardfulCoupon, setRewardfulCoupon] = useState(null);
  const [paddle, setPaddle] = useState(null);
  const setupRewardful = () => {
    //Rewardful scripts are loaded in the header
    window.rewardful("ready", () => {
      if (window.Rewardful.referral) {
        setReferral(window.Rewardful.referral);
      }
      if (window.Rewardful.coupon) {
        setRewardfulCoupon(window.Rewardful.coupon.id);
      }
    });
  };
  const setupPaddle = () => {
    initializePaddle({ environment: process.env.REACT_APP_PADDLE_ENVIRONMENT, token: process.env.REACT_APP_PADDLE_CLIENT_KEY }).then(
      (paddleInstance) => {
        if (paddleInstance) {
          setPaddle(paddleInstance);
        }
      },
    );
  }

  useEffect(() => {
    setupRewardful();
    setupPaddle();
  }, []);

  const showProrateConfirm = (amount, currency, onConfirm) => {
    setProrateComponent(
      <ProrateOverlay
        amount={amount}
        currency={currency}
        onConfirm={onConfirm}
        onReturn={() => setOverlay("")}
      />
    );
    setOverlay("prorate");
  };

  const showCouponConfirm = () => {
    setCouponComponent(<CouponOverlay onReturn={() => setOverlay("")} />);
    setOverlay("coupon");
  };

  let currentOverlay = overlay;
  if (loading) {
    return (
      <DashboardPanel gap="1rem" icon={<PaymentInfoIcon />} size="wide">
        <LoadingSpinner />
      </DashboardPanel>
    );
  } else if (billing.hasPendingPayment) {
    return (
      <DashboardPanel gap="1rem" icon={<PaymentInfoIcon />} size="wide">
        <PendingPaymentOverlay />
      </DashboardPanel>
    );
  } else if (currentOverlay == "prorate") {
    return (
      <DashboardPanel gap="1rem" icon={<PaymentInfoIcon />} size="wide">
        {prorateComponent}
      </DashboardPanel>
    );
  } else if (currentOverlay == "coupon") {
    return (
      <DashboardPanel gap="1rem" icon={<PaymentInfoIcon />} size="wide">
        {couponComponent}
      </DashboardPanel>
    );
  }

  let showLicense = licenses.lifetime == null && licenses.perpetual == null;
  let showSubscription =
    licenses.lifetime == null &&
    licenses.active == null &&
    licenses.upcoming == null;

  let showOr = !(billing.account == "" && recurring.status == "inactive") && showSubscription;



  return (
    <DashboardPanel gap="1rem" icon={<PaymentInfoIcon />} size="wide">
      {/* {showLicense && (
        <PurchaseLicenseDetails
          licenses={licenses}
          referral={referral}
          coupons={coupons}
          rewardfulCoupon={rewardfulCoupon}
          paddle={paddle}
        />
      )}
      {showOr && <OrDivider />} */}
      {showSubscription && (
        <PurchaseSubscriptionDetail
          referral={referral}
          rewardfulCoupon={rewardfulCoupon}
          recurring={recurring}
          coupons={coupons}
          inTrial={inTrial}
          showProrateConfirm={showProrateConfirm}
        />
      )}
      {showOr && <><br /><Divider /></>}
      <BillingDetails recurring={recurring} billing={billing}/>
      <div className="user-button">
        <a
          className="musio-link"
          target="_blank"
          href="https://musio.com/pricing"
        >
          See Pricing
        </a>
      </div>
      {/* <div>
        <MusioButton onClick={showCouponConfirm} text="Add Coupon" />
      </div> */}
    </DashboardPanel>
  );
};

const Divider = (props) => {
  return (
    <div className="or-div">
      <hr className="or-hr" />
    </div>
  );
};

const PurchaseSubscriptionDetail = (props) => {
  const [loading, setLoading] = useState(false);
  const [showEndTrialConfirm, setShowEndTrialConfirm] = useState(false);
  const [showProrateConfirm, setShowProrateConfirm] = useState(false);

  const startMembership = (e) => {
    e.preventDefault();
    setLoading(true);
    startRecurring("monthly", props.referral, props.rewardfulCoupon);
  };

  const resumeMembership = (e) => {
    e.preventDefault();
    setLoading(true);
    resumeRecurring().then((resp) => {
      PubSub.publish("RefreshMembership", true);
      setLoading(false);
    });
  };

  const handleChangeMembership = (e, currentPlan, planToChangeTo) => {
    e.preventDefault();
    if (
      currentPlan === "monthly" &&
      (planToChangeTo === "annual" || planToChangeTo === "earlyAnnual")
    ) {
      prorateMembership(planToChangeTo);
    } else {
      changeMembership(planToChangeTo);
    }
  };



  const prorateMembership = (plan) => {
    setLoading(true);
    prorateRecurring(plan).then((resp) => {
      setLoading(false);
      props.showProrateConfirm(resp.data.amount, resp.data.currency, () => {
        return changeMembership(plan).then((resp) =>
          PubSub.publish("RefreshMembership", true)
        );
      });
    });
  };

  const changeMembership = (plan) => {
    setLoading(true);
    return changeRecurring(plan, props.referral) //no coupon for changing prices
      .then((resp) => {
        setLoading(false);
      });
  };

  const activePlan = props.recurring.plan;
  const showResume =
    props.recurring.status == "active" && props.recurring.canceled;

  //if user has active sub, but pending cancellation
  if (props.recurring.status == "active" && props.recurring.canceled) {
    const price_text = priceString(props.recurring.plan);
    return (
      <div className="purchase-container">
        <div className="purchase-copy">
          <h2 className="redtext" style={{ marginBottom: "0.4rem" }}>
            Subscription
          </h2>
          <p>Ongoing access to ALL Musio instruments and catalog additions</p>
        </div>
        <div className="purchase-btn-container">
          <MusioButton
            buttonType="squared"
            loading={loading}
            text={<span>renew membership</span>}
            onClick={resumeMembership}
          />
          <p>{price_text}</p>
        </div>
      </div>
    );
  } else if (props.recurring.upcomingPlan) {
    //if user has upcoming change
    console.assert(props.recurring.upcomingPlan === "monthly");
    return (
      <div className="purchase-container">
        <div className="purchase-copy">
          <h2 className="redtext" style={{ marginBottom: "0.4rem" }}>
            Subscription
          </h2>
          <div>
            <h3>
              Upcoming Plan:{" "}
              <span className="subtext">
                {planString(props.recurring.upcomingPlan)}
              </span>
            </h3>
            <p className="redtext">
              starts on {props.recurring.upcomingPeriodStart}
            </p>
          </div>
        </div>

        <div className="purchase-btn-container">
          <MusioButton
            buttonType="squared"
            loading={loading}
            text={<span>resume annual billing</span>}
            onClick={(e) =>
              handleChangeMembership(
                e,
                props.recurring.plan,
                props.recurring.plan
              )
            }
          />
          <p style={{ marginTop: "0.5rem" }}>$99/yr</p>
        </div>
      </div>
    );
  } else if (props.recurring.status == "active") {
    //if has active sub
    const planToChangeTo =
      props.recurring.plan === "monthly" ? "annual" : "monthly";
    return (
      <div className="purchase-container">
        <div className="purchase-copy">
          <h2 className="redtext" style={{ marginBottom: "0.4rem" }}>
            Subscription
          </h2>
          <h3>
            Current Plan:{" "}
            <span className="subtext">{planString(props.recurring.plan)}</span>
          </h3>
          {planToChangeTo === "annual" && (
            <p className="subtext">save 18% with annual billing</p>
          )}
          {props.upcomingPlan && (
            <h3>
              Upcoming Plan:{" "}
              <span className="subtext">
                {planString(props.recurring.upcomingPlan)}
              </span>
            </h3>
          )}
        </div>
        <div className="purchase-btn-container">
          <MusioButton
            buttonType="squared"
            loading={loading}
            text={<span>change to {planToChangeTo}</span>}
            onClick={(e) =>
              handleChangeMembership(e, props.recurring.plan, planToChangeTo)
            }
          />
          <p style={{ marginTop: "0.5rem" }}>
            {props.recurring.plan == "annual" ? "$9.99/mo" : "$99/yr"}
          </p>
          {/* {props.recurring.plan === 'earlyAnnual' && <p className="redtext">You cannot resume the early annual pricing</p>} */}
        </div>
      </div>
    );
  }

  //user does not have a sub
  let price = 9.99;
  let coupon = getBestCouponForType(props.coupons, "recurring");

  //create a coupon locally
  // if (coupon === null) {
  //   coupon = {
  //     description: "$5 off first 5 months",
  //     usdValue: 25,
  //     type: "recurring"
  //   }
  // }
  //  -------

  const renderPriceText = () => {
    if (coupon !== null) {
      const couponPrice = price - coupon.usdValue;
      return (
        <p style={{ marginTop: "0.5rem" }}>
          $9.99/mo <span className="subtext">or </span>$99/yr <br />
          <span className="redtext">
            Coupon: {coupon.description}
          </span>
        </p>
      );
    }
    return (
      <p style={{ marginTop: "0.5rem" }}>
        $9.99/mo <span className="subtext">or </span>$99/yr
      </p>
    );
  };

  return (
    <div className="purchase-container">
      <div className="purchase-copy">
        <h2 className="redtext" style={{ marginBottom: "0.4rem" }}>
          Subscription
        </h2>
        <p>Ongoing access to ALL Musio instruments and catalog additions</p>
      </div>
      <div className="purchase-btn-container">
        <MusioButton
          buttonType="squared"
          loading={loading}
          text={<span>Sign Up</span>}
          onClick={startMembership}
        />
        {renderPriceText()}
      </div>
    </div>
  );
};

const PendingPaymentOverlay = (props) => {
  return (
    <div className="payment-pending-container">
      <div>
        <h3>Processing your recent purchase!</h3>
        <br />
        <p className="redtext">Please check back later or refresh the page</p>
        <p className="subtext">
          NOTE: Some payment methods take 1+ day(s) to authorize and debit. If
          you believe the process is taking longer than expected please contact
          support
        </p>
      </div>
    </div>
  );
};

const ProrateOverlay = (props) => {
  const [loading, setLoading] = useState(false);

  const currencySymbol = getSymbolFromCurrency(props.currency.toUpperCase());
  return (
    <div className="prorate-container">
      <div>
        <p className="redtext">you are about to upgrade your plan.</p>
        <p className="subtext">
          a charge of {currencySymbol}
          {parseFloat(props.amount) / 100.0} will be made immediately.
        </p>
      </div>
      <div className="confirm-container">
        <MusioButton
          buttonType="squared"
          background="grey"
          onClick={props.onReturn}
          text="cancel."
        />
        <MusioButton
          loading={loading}
          buttonType="squared"
          onClick={() => {
            setLoading(true);
            props.onConfirm().then(() => props.onReturn());
          }}
          text="confirm."
        />
      </div>
    </div>
  );
};

const CouponOverlay = (props) => {
  const [loading, setLoading] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const submitCouponCode = (e) => {
    e.preventDefault();
    const couponCode = e.target.couponCode.value;
    console.log(`Coupon code submitted: ${couponCode}`); // log the coupon code

    setLoading(true);
    const cleanCoupon = couponCode.trim();
    axios
      .post("/api/v1/user/coupons/redeem", { token: cleanCoupon })
      .then((resp) => {
        PubSub.publish("RefreshMembership", true);
        props.onReturn();
      })
      .catch((err) => {
        console.log(err);
        setErrorMessage(err.response.data.error);
        setLoading(false);
      });
  };

  return (
    <div className="coupon-container">
      <form onSubmit={submitCouponCode}>
        <div className="redeem-title">
          <label htmlFor="couponCode" style={{ marginBottom: "10px" }}>
            Coupon Code:
          </label>
          <input
            style={{ marginTop: "10px" }}
            type="text"
            id="couponCode"
            name="couponCode"
            placeholder="ABC02357518"
          />
          <ErrorMessage text={errorMessage} />
        </div>

        <div className="confirm-container">
          <MusioButton
            buttonType="squared"
            background="grey"
            onClick={props.onReturn}
            text="Cancel"
          />
          <MusioButton loading={loading} buttonType="squared" text="Add" />
        </div>
      </form>
    </div>
  );
};

const getBestCouponForType = (coupons, typeToMatch) => {
  let bestCoupon = null;

  //check if 'available' in coupons
  if ("available" in coupons) {
    //iterate each available coupon
    for (const coupon of coupons.available) {
      if (coupon.type !== typeToMatch) continue; //skip

      //assign the first coupon
      if (bestCoupon === null) {
        bestCoupon = coupon;
      } else {
        // if we already have a bestCoupon, see if there's a better value
        if (parseFloat(bestCoupon.usdValue) < parseFloat(coupon.usdValue)) {
          bestCoupon = coupon;
        }
      }
    }
  }

  return bestCoupon;
};

const PurchaseLicenseDetails = (props) => {
  const [loading, setLoading] = useState(false);

  const handleMusio1Purchase = () => {
    setLoading(true);

    console.log(GetMusio1PriceId())
    var itemList = [
      {
        priceId: GetMusio1PriceId(),
        quantity: 1
      }
    ]

    props.paddle?.Checkout.open({
      items: itemList,
      settings: {theme: "dark"}
    });
    
    // purchaseMusio1License(props.referral, props.rewardfulCoupon).then(
    //   (resp) => {}
    // );
  };

  const parseLicense = (license) => {
    if (["MUSIO_ONE", "MUSIO_ONE_UPGRADE"].includes(license.subject)) {
      return `Musio 1`;
    } else {
      return license.subject;
    }
  };

  let price = 399.0;
  let coupon = getBestCouponForType(props.coupons, "musio1");

  // create a coupon locally
  if (coupon === null) {
    coupon = {
      description: "$200 off",
      usdValue: 200,
      type: "musio1"
    }
  }
  // -------

  const renderPriceText = () => {
    if (coupon !== null) {
      const couponPrice = price - coupon.usdValue;
      return (
        <p style={{ marginTop: "0.5rem" }}>
          <s className="subtext">${price}</s>
          &nbsp; ${couponPrice} <br />
          <span className="redtext">
            Coupon: {coupon.description}
          </span>
        </p>
      );
    }
    return <p style={{ marginTop: "0.5rem" }}>${price}</p>;
  };

  return (
    <div className="license-container">
      <div className="license-copy">
        <h2 className="redtext" style={{ marginBottom: "0.4rem" }}>
          Musio 1
        </h2>
        <p>
          Perpetual access to all 1800+ instruments on the{" "}
          <a
            className="musio-link"
            href="https://musio.com/whats-included/"
            target="_blank"
          >
            2023 roadmap
          </a>
          . Pay once, use forever.
        </p>
      </div>
      {!props.licenses.perpetual && !props.licenses.hasLifetime && (
        <div className="purchase-btn-container">
          <MusioButton
            buttonType="squared"
            loading={loading}
            text={<span>Buy Musio 1</span>}
            onClick={handleMusio1Purchase}
          />
          {renderPriceText()}
        </div>
      )}
    </div>
  );
};

export default PurchasePanel;
