import axios from 'axios';
import { portalStore, refreshStore, getToken, setAuth, removeAuth, actionSet} from 'store';

export const isLoggedIn = () => {
    let token = getToken();
    return (token !== undefined && token !== null);
};

const setIsLoggedIn = (flag) => {
    portalStore.dispatch(actionSet('isLoggedIn', flag))
}

export const loginWithCredentials = (email, password) => {
    const fingerprint = portalStore.getState().fingerprint;
    console.assert(fingerprint != null);

    return axios({method: "POST", url: "/api/v1/token", 
        data: {
            authId: email,
            password: password,
            machineId: fingerprint,
            apiKey: process.env.REACT_APP_API_KEY
        }
    })
    .then(resp => {
        axios.defaults.headers.common['Authorization'] = "Bearer " + resp.data.token;
        setAuth(resp.data.token, email, resp.data.contactName);
        setIsLoggedIn(true);
    })
};

export const loginWithToken = (token) => {
    const fingerprint = portalStore.getState().fingerprint;
    console.assert(fingerprint != null);

    return axios({method: "POST", url: "/api/v1/token", 
        data: {
            userToken: token,
            machineId: fingerprint,
            apiKey: process.env.REACT_APP_API_KEY
        }
    })
    .then(resp => {
        axios.defaults.headers.common['Authorization'] = "Bearer " + resp.data.token;
        setAuth(resp.data.token, resp.data.contactName);
        setIsLoggedIn(true);
    })
}

export const checkLogin = async () => {
    if (isLoggedIn()) {
        refreshStore();
        axios.defaults.headers.common['Authorization'] = "Bearer " + getToken()
        return axios.get('api/v1/token')
        .then(resp=>{
            setIsLoggedIn(true);
        })
        .catch(err => {
            setIsLoggedIn(false);
        });
    }
};

export const logout = () => {
    delete axios.defaults.headers.common['Authorization'];
    setIsLoggedIn(false);
    removeAuth();
    refreshStore();
}
