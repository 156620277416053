import axios from 'axios';
import React, {useEffect, useState } from 'react';

import './style.css';
import MusioLogo from 'components/MusioLogo';

const EmailChangeConfirm = (props) => {

    let [message, setMessage] = useState('')

    const clearToken = (token) => {
        return axios.get('/api/v1/user/email', { params: {token: token} })
        .then((resp)=>{
            setMessage("email successfully changed.")
        })
        .catch((err)=>{
            console.log(err);
            window.location.href='/error';
        })
    }

    useEffect( () => { // mount
        //extract params
        const search = window.location.search;
        const params = new URLSearchParams(search);
        const token = params.get('token');
        if (token) {
            clearToken(token);
        }
        else {
            window.location.href='/error';
        }
    }, [] );


    return (
        <div className='onboarding-container'>
            <MusioLogo />
            <div className="onboarding-spacer" />
            <h1 className='onboarding-header'>{message}</h1>
        </div>
    );
};

export default EmailChangeConfirm;
