import { createStore } from "redux";

//STORE SETUP
const initialState = {
  trial: {},
  recurring: {},
  billing: {},
  licenses: {},
  coupons: {},
  launchCheckout: {},
  contactName: "",
  userId: "",
  isLoggedIn: false,
  isFetching: false   //ugly js but handy to syncronize fetching all data at once - see <Dashboard>
};

const rootReducer = (state = initialState, action) => {
  switch (action.type) {
    case "set": //generic way to set key: value in the global store
      return {
        ...state,
        [action.payload.key]: action.payload.value,
      };
    default: //do nothing
      return state;
  }
};

export const portalStore = createStore(
  rootReducer,
  window.__REDUX_DEVTOOLS_EXTENSION__ && window.__REDUX_DEVTOOLS_EXTENSION__()
);

export const actionSet = (payloadKey, payloadValue) => {
  return {
    type: "set",
    payload: { key: payloadKey, value: payloadValue },
  };
};

//LOCAL_STORAGE
const LOCAL_STORAGE_TOKEN_KEY = "token";
const LOCAL_STORAGE_CONTACT_NAME_KEY = "contactName";
const LOCAL_STORAGE_EMAIL_KEY = "email";
const LOCAL_STORAGE_LAUNCH_CHECKOUT = "launchCheckout";

export const getToken = () => {
  let token = localStorage.getItem(LOCAL_STORAGE_TOKEN_KEY);
  return token;
};

export const setAuth = (token, email, contactName) => {
  localStorage.setItem(LOCAL_STORAGE_TOKEN_KEY, token);
  localStorage.setItem(LOCAL_STORAGE_CONTACT_NAME_KEY, contactName);
  localStorage.setItem(LOCAL_STORAGE_EMAIL_KEY, email);
};

export const removeAuth = () => {
  localStorage.removeItem(LOCAL_STORAGE_TOKEN_KEY);
  localStorage.removeItem(LOCAL_STORAGE_CONTACT_NAME_KEY);
  localStorage.removeItem(LOCAL_STORAGE_EMAIL_KEY);
  portalStore.dispatch(actionSet("isLoggedIn", false));
  // localStorage.clear();
};

export const refreshStore = () => {
  let contactName = localStorage.getItem(LOCAL_STORAGE_CONTACT_NAME_KEY);
  let email = localStorage.getItem(LOCAL_STORAGE_EMAIL_KEY);
  portalStore.dispatch(actionSet("contactName", contactName));
  portalStore.dispatch(actionSet("email", email));
};

export const setLaunchCheckout = (plan) => {
  localStorage.setItem(LOCAL_STORAGE_LAUNCH_CHECKOUT, plan);
}

export const getLaunchCheckout = () => {
  return localStorage.getItem(LOCAL_STORAGE_LAUNCH_CHECKOUT);
}

export const clearLaunchCheckout = () => {
  localStorage.removeItem(LOCAL_STORAGE_LAUNCH_CHECKOUT);
}