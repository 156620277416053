import React from 'react';

export default function MacOSIcon () {
    return (
<svg style={{marginTop: "-0.3rem"}} width="24" height="29" viewBox="0 0 24 29" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g clipPath="url(#clip0_4614_499)">
        <path d="M23.497 22.2045C23.0661 23.1999 22.5561 24.1163 21.9651 24.9587C21.1596 26.1072 20.5001 26.9021 19.9918 27.3436C19.2039 28.0682 18.3597 28.4393 17.4557 28.4604C16.8067 28.4604 16.024 28.2757 15.113 27.9011C14.199 27.5282 13.359 27.3436 12.5909 27.3436C11.7854 27.3436 10.9215 27.5282 9.99748 27.9011C9.07202 28.2757 8.32648 28.4709 7.75647 28.4903C6.88958 28.5272 6.0255 28.1456 5.16301 27.3436C4.61252 26.8634 3.92396 26.0403 3.09911 24.8743C2.2141 23.6291 1.4865 22.1851 0.916488 20.5389C0.306024 18.7608 0 17.039 0 15.3721C0 13.4626 0.412604 11.8157 1.23904 10.4356C1.88855 9.32704 2.75263 8.45259 3.83409 7.81064C4.91555 7.1687 6.08407 6.84157 7.34246 6.82064C8.03101 6.82064 8.93396 7.03363 10.056 7.45221C11.175 7.8722 11.8934 8.08519 12.2084 8.08519C12.4439 8.08519 13.242 7.83615 14.595 7.33965C15.8745 6.87921 16.9544 6.68856 17.8391 6.76366C20.2363 6.95712 22.0372 7.9021 23.2349 9.60458C21.091 10.9036 20.0305 12.723 20.0516 15.0571C20.0709 16.8751 20.7305 18.388 22.0267 19.5892C22.6141 20.1467 23.2701 20.5776 24 20.8837C23.8417 21.3427 23.6746 21.7824 23.497 22.2045ZM17.9991 0.570017C17.9991 1.99498 17.4785 3.32546 16.4409 4.55694C15.1886 6.02093 13.674 6.8669 12.0315 6.73341C12.0106 6.56245 11.9984 6.38253 11.9984 6.19347C11.9984 4.82551 12.5939 3.36152 13.6515 2.16451C14.1795 1.55844 14.8509 1.0545 15.6653 0.652502C16.4778 0.2565 17.2464 0.0375024 17.9692 0C17.9903 0.190495 17.9991 0.381021 17.9991 0.570017Z" fill="white"/>
    </g>
    <defs>
        <clipPath id="clip0_4614_499">
            <rect width="24" height="28.4927" fill="white"/>
        </clipPath>
    </defs>
</svg>
);
};


