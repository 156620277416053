import React, { useState, useEffect } from 'react';
import { useSelector } from "react-redux";
import { isLoaded, fetchUserInfo, fetchBillingInfo, startStripeBillingSession, symbolForCurrency } from 'store/resources';

import DashboardPanel from 'components/DashboardPanel/index.js';
import UserInfoIcon from 'icons/UserInfoIcon.js';
import LoadingSpinner from 'components/LoadingSpinner'
import './style.css'

import { UserDetails, EditEmail, EditPassword, EditName } from './UserDetails';
import { BillingDetails } from '../BillingDetails';






const UserPanel = (props) => {
  const [overlay, setOverlay] = useState('');

  //fetch all data 
  const recurring = useSelector(state => state.recurring);
  const billing = useSelector(state => state.billing);
  
  useEffect( () => { // mount
    fetchUserInfo();
  }, [] );
  
  useEffect( () => () => { // unmount
  }, [] );

  const showEditEmail = ()=> {
    setOverlay('editEmail');
  };

  const showEditName = ()=> {
    setOverlay('editName');
  };

  const showEditPassword = ()=> {
    setOverlay('editPassword');
  };

  const closeOverlay = () => {
    setOverlay('');
  }

  let activeComponent = '';
  if (!isLoaded(recurring) || !isLoaded(billing)) {
    activeComponent = <div><LoadingSpinner/></div>;
  }
  else if (overlay == 'editEmail') {
    activeComponent = <EditEmail onClose={closeOverlay}/>
  }
  else if (overlay == 'editName') {
    activeComponent = <EditName onClose={closeOverlay}/>
  }
  else if (overlay == 'editPassword') {
    activeComponent = <EditPassword onClose={closeOverlay}/>
  }
  else
  {
    activeComponent = <>
      <UserDetails showEditEmail={showEditEmail} showEditName={showEditName} showEditPassword={showEditPassword}/>
    </>;
  }

  return (
    <DashboardPanel icon={<UserInfoIcon />}>
        {activeComponent}
      </DashboardPanel>
  );
};

export default UserPanel;
