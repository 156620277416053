import React from 'react';
import './style.css';

import PencilIcon from 'icons/PencilIcon';

const MusioEditPencil = (props) => {
  let disabled = props.disabled || false;
//   let button_type = props.buttonType || "round";  //round or squared
//   let background = props.background || "red";
//   let loading = props.loading || false;
//   let size = props.size || "large";

  return (
    <button className={`musio-edit button`} onClick={props.onClick} disabled={disabled}>
        <PencilIcon/>
    </button>
  );
};
  
export default MusioEditPencil;