import axios from 'axios';
import React, {useEffect, useState } from 'react';
import {
  Link,
  useParams
} from "react-router-dom";

import {getLCDistribution, createLCDistributionCode} from "store/resources";
import './style.css';
import MusioButton from 'components/MusioButton';
import MusioLogo from 'components/MusioLogo';
import LoadingRing from 'components/LoadingRing'


const DistributeLicenseCode = (props) => {
  let { id } = useParams();
  const [currentStep, setCurrentStep] = useState('loading');  //loading, form, submit, complete
  const [distInfo, setDistInfo] = useState(null);
  const [email, setEmail] = useState(null);

  useEffect( () => { // mount
    //get dist
    getLCDistribution(id)
    .then((resp)=>{
      setDistInfo(resp.data);
      setTimeout(()=>{
        setCurrentStep('form')
      }, 1000);
    })
    .catch((err)=>{
      setCurrentStep('expired');
    });

    //  error if not found

    // requestTrialRestart(uuid)
    

  }, [] );

  let onSubmit = (e) => {
    e.preventDefault();

    createLCDistributionCode(id, email)
    .then((resp)=>{
      setCurrentStep('complete');
    })
    .catch((err)=>{
      
    })

    setCurrentStep('submitted')
  };

  return (
    <div className='onboarding-container'>
      <MusioLogo />
      <div className="onboarding-spacer" />

      {currentStep == 'loading' && 
        <div>
          <h1 className='onboarding-header'>Loading...</h1><br/>
          <LoadingRing />
        </div>
      }

      {currentStep == 'form' &&
        <div>
          <h1 className='onboarding-header'>Request License Code</h1>
          <p className="subtext">Brand: {distInfo.brand}</p><br/>
          <form onSubmit={onSubmit} className='user-edit-form'>
            <label>
                <div className='user-edit-title'>Email Address</div>
                <input className='edit-user-info' placeholder='' onChange={(e)=>setEmail(e.target.value)} type="email"/>
            </label>
            <div>
                <MusioButton size="small" buttonType="squared" text="Submit" type="submit"/>&nbsp;&nbsp;
            </div>

          </form>
        </div>
      }

      {currentStep == 'submitted' &&
        <div>
          <h1 className='onboarding-header'>Submitting License Code Request</h1><br/>
          <LoadingRing />
        </div>
      }

      {currentStep == 'complete' &&
        <div>
          <h1 className='onboarding-header'>Request Complete!</h1><br/>
          <p className='subtext'>Check your email for a license code</p><br/>
          <Link to="/login">
            <MusioButton text="log in" />
          </Link>
        </div>
      }

      {currentStep == 'expired' &&
        <div>
          <h1 className='onboarding-header'>Request Error!</h1><br/>
          <p className='subtext'>The URL provided does not exist or is expired</p><br />
          <Link to="/login">
            <MusioButton text="log in" />
          </Link>
        </div>
      }
      
    </div>
  );
};

export default DistributeLicenseCode;
