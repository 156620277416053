import React from 'react';

export default function TrialClockIcon(){
    return(
<svg className='trialClock' width="129" height="141" viewBox="0 0 129 141" fill="none" xmlns="http://www.w3.org/2000/svg">
    <rect x="-2.03646" y="0.322543" width="23.7513" height="2.91588" transform="matrix(-0.809017 -0.587785 -0.587785 0.809017 106.959 33.7738)" fill="#FB2645" stroke="#FB2645" strokeWidth="2.91588"/>
    <path d="M36.0039 126.997L24.5039 117.997L17.0039 138.997H23.0039L36.0039 126.997Z" stroke="#807D8E" strokeWidth="3"/>
    <path d="M93.0039 126.997L104.504 117.997L112.004 138.997H106.004L93.0039 126.997Z" stroke="#807D8E" strokeWidth="3"/>
    <rect x="20.7339" y="34.992" width="23.6672" height="3.14008" transform="rotate(-36 20.7339 34.992)" fill="#FB2645" stroke="#FB2645" strokeWidth="3"/>
    <circle cx="64.5043" cy="77.4974" r="56.1293" stroke="#807D8E" strokeWidth="3"/>
    <circle cx="64.502" cy="77.498" r="46" stroke="#807D8E" strokeWidth="3"/>
    <circle cx="64.502" cy="77.4971" r="4" stroke="#FB2645" strokeWidth="3"/>
    <path d="M62.9667 35.5333C62.9667 34.6854 63.6541 33.998 64.502 33.998C65.3498 33.998 66.0372 34.6854 66.0372 35.5333V39.6589C66.0372 40.4965 65.3396 41.1628 64.502 41.1628C63.6643 41.1628 62.9667 40.4965 62.9667 39.6589V35.5333Z" fill="#807D8E"/>
    <path d="M46.3296 46.0265C47.0539 45.6074 47.3262 44.6816 46.9078 43.9569L44.847 40.3875C44.4231 39.6531 43.484 39.4016 42.7497 39.8255C42.0154 40.2495 41.7638 41.1884 42.1878 41.9228L44.2486 45.4922C44.6676 46.2179 45.6042 46.4462 46.3296 46.0265Z" fill="#807D8E"/>
    <path d="M33.0294 59.3274C33.4491 58.6021 33.2207 57.6656 32.495 57.2466L28.9257 55.1858C28.1913 54.7618 27.2524 55.0134 26.8284 55.7477C26.4045 56.4821 26.656 57.421 27.3904 57.845L30.9596 59.9057C31.6845 60.3242 32.6102 60.0519 33.0294 59.3274Z" fill="#807D8E"/>
    <path d="M28.1667 77.498C28.1667 76.6604 27.5004 75.9628 26.6628 75.9628H22.5372C21.6893 75.9628 21.002 76.6501 21.002 77.498C21.002 78.346 21.6893 79.0333 22.5372 79.0333H26.6628C27.5004 79.0333 28.1667 78.3357 28.1667 77.498Z" fill="#807D8E"/>
    <path d="M33.0289 95.6678C32.6098 94.9433 31.684 94.671 30.9591 95.0895L27.3892 97.1506C26.6549 97.5746 26.4033 98.5135 26.8273 99.2479C27.2512 99.9821 28.1902 100.234 28.9245 99.8098L32.4945 97.7486C33.2202 97.3296 33.4485 96.3931 33.0289 95.6678Z" fill="#807D8E"/>
    <path d="M46.3293 108.969C45.604 108.55 44.6673 108.778 44.2483 109.504L42.1878 113.073C41.7638 113.807 42.0154 114.746 42.7497 115.17C43.4841 115.594 44.4231 115.342 44.847 114.608L46.9074 111.039C47.3259 110.314 47.0536 109.389 46.3293 108.969Z" fill="#807D8E"/>
    <path d="M64.502 113.833C63.6643 113.833 62.9667 114.5 62.9667 115.337V119.463C62.9667 120.311 63.6541 120.998 64.502 120.998C65.3498 120.998 66.0372 120.311 66.0372 119.463V115.337C66.0372 114.5 65.3396 113.833 64.502 113.833Z" fill="#807D8E"/>
    <path d="M82.6715 108.971C81.947 109.39 81.6747 110.316 82.0932 111.041L84.1525 114.608C84.5765 115.342 85.5154 115.594 86.2497 115.17C86.9841 114.746 87.2356 113.807 86.8117 113.073L84.7524 109.506C84.3334 108.78 83.3968 108.552 82.6715 108.971Z" fill="#807D8E"/>
    <path d="M95.9741 95.6694C95.5544 96.3947 95.7827 97.3314 96.5084 97.7504L100.075 99.8097C100.81 100.234 101.749 99.9821 102.173 99.2477C102.597 98.5135 102.345 97.5744 101.611 97.1505L98.0438 95.0912C97.319 94.6727 96.3932 94.945 95.9741 95.6694Z" fill="#807D8E"/>
    <path d="M100.837 77.498C100.837 78.3357 101.503 79.0333 102.341 79.0333H106.467C107.315 79.0333 108.002 78.346 108.002 77.498C108.002 76.6501 107.315 75.9628 106.467 75.9628H102.341C101.503 75.9628 100.837 76.6604 100.837 77.498Z" fill="#807D8E"/>
    <path d="M95.9736 59.3259C96.3928 60.0502 97.3185 60.3225 98.0433 59.9041L101.61 57.8451C102.344 57.4211 102.595 56.4821 102.172 55.7478C101.748 55.0135 100.809 54.7619 100.074 55.1859L96.5079 57.2449C95.7822 57.6639 95.5539 58.6005 95.9736 59.3259Z" fill="#807D8E"/>
    <path d="M82.6711 46.0247C83.3964 46.4443 84.3331 46.2159 84.752 45.4902L86.8117 41.9228C87.2356 41.1884 86.9841 40.2495 86.2497 39.8255C85.5154 39.4016 84.5765 39.6531 84.1525 40.3875L82.0928 43.9549C81.6743 44.6798 81.9466 45.6056 82.6711 46.0247Z" fill="#807D8E"/>
    <path d="M63.002 45.4971C63.002 44.6686 63.6735 43.9971 64.502 43.9971C65.3304 43.9971 66.002 44.6686 66.002 45.4971V72.9971H63.002V45.4971Z" fill="#FB2645"/>
    <path d="M82.5513 69.9101C83.3374 69.6485 84.1866 70.0738 84.4481 70.8598C84.7097 71.6459 84.2844 72.4951 83.4984 72.7566L69.2436 77.4993L68.2965 74.6527L82.5513 69.9101Z" fill="#FB2645"/>
    <path d="M11.9521 40.732C11.7928 40.4318 11.6195 40.0838 11.4425 39.6926C10.7511 38.1638 10.018 36.0037 9.82989 33.4607C9.46189 28.4863 11.1511 21.7352 20.1966 15.1633C29.2421 8.59135 36.1848 9.07098 40.802 10.9581C43.1624 11.9229 44.9903 13.2876 46.2306 14.4176C46.548 14.7068 46.8253 14.9792 47.0616 15.2235L11.9521 40.732Z" stroke="#807D8E" strokeWidth="3"/>
    <path d="M117.317 40.7318C117.477 40.4311 117.651 40.0824 117.828 39.6903C118.52 38.1604 119.254 35.9987 119.443 33.4542C119.813 28.4768 118.126 21.7232 109.081 15.1516C100.036 8.57993 93.0912 9.06204 88.4716 10.952C86.1101 11.9181 84.2811 13.2842 83.0399 14.4154C82.7219 14.7053 82.444 14.9783 82.2073 15.2231L117.317 40.7318Z" stroke="#807D8E" strokeWidth="3"/>
</svg>);
};

