import axios from 'axios';
import React, {useEffect, useState } from 'react';

import {requestTrialRestart} from "store/resources";
import './style.css';
import {Link} from 'react-router-dom'
import MusioButton from 'components/MusioButton';
import MusioLogo from 'components/MusioLogo';
import LoadingRing from 'components/LoadingRing'


const TrialRestart = (props) => {

  const [loading, setLoading] = useState(true);

  useEffect( () => { // mount
    //extract params
    const search = window.location.search;
    const params = new URLSearchParams(search);
    const uuid = params.get('uuid');
    console.log(uuid);
    requestTrialRestart(uuid)
    .then((resp)=>{
      setTimeout(()=>{
        setLoading(false);
      }, 1000);
    })
    .catch((err)=>{
      window.location.href='/error';
    });

  }, [] );


  return (
    <div className='onboarding-container'>
      <MusioLogo />
      <div className="onboarding-spacer" />

      {loading && 
        <div>
          <h1 className='onboarding-header'>Requesting Trial Restart</h1><br/>
          <LoadingRing />
        </div>
      }

      {!loading && 
        <div>
          <h1 className='onboarding-header'>Request Complete!</h1><br/>
          <Link to="/login">
            <MusioButton text="log in" />
          </Link>
        </div>
      }
      
    </div>
  );
};

export default TrialRestart;
