import React from 'react';
import {connect} from 'react-redux'
import {Link} from 'react-router-dom'
import axios from 'axios';
// import { actionSet, login, logout } from 'store'
import { loginWithCredentials } from 'store/authentication';

import ErrorMessage from 'components/ErrorMessage';
import MusioLogo from 'components/MusioLogo';
import MusioButton from 'components/MusioButton';

import './style.css';

class Login extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      email: '',
      password: '',
      loginError:'',
      isLoading: false
    }

    this.handleLogin =this.handleLogin.bind(this)
  }

  render() {
    return(
      <div className='onboarding-container'>

        <MusioLogo />
        <div className="onboarding-spacer" />
        <ErrorMessage text={this.state.loginError} />

        <h1 className='onboarding-header'>log in.</h1>
        <div className="onboarding-spacer" />
        <form className="onboarding-form" onSubmit={this.handleLogin}>
          <input className='input' type='email' placeholder=' email...' onChange={ (e)=>{this.setState({email: e.target.value});} }/>
          <input className='input' type='password' placeholder=' password...' onChange={ (e)=>{this.setState({password: e.target.value});} }/>
        
          <Link className="onboarding-link" to="/signup">create an account</Link>
          <div className="onboarding-spacer" />
          <div><MusioButton loading={false} text="log in" /></div>
          
          <Link className="onboarding-link" to="/recover">recover account</Link>
        </form>
      </div>
    )
  }

  handleLogin(e) {
    this.setState({isLoading:true, loginError: ''});
    e.preventDefault();
    loginWithCredentials(this.state.email, this.state.password)
    .catch(err => {
      if (!err.response) {
        //network error - service unreachable
        this.setState({loginError: 'service unreachable, please try again later.', isLoading:false});
      }
      else {
        this.setState({loginError:'the email address or password is incorrect, or the email has not been verified yet. please try again.', isLoading:false});
      }
    });
  }
};

export default Login;
