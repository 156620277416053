import React, { useState, useEffect } from 'react';
import { useSelector } from "react-redux";
import axios from 'axios';
import { fetchUserInfo } from 'store/resources';

import MusioEditPencil from 'components/MusioEditPencil'
import ErrorMessage from 'components/ErrorMessage';
import MusioButton from 'components/MusioButton';
import './style.css'

const UserProperty = (props) => {
  const on_edit = props.onEdit || null;
  const show_button = on_edit !== null;
  return (
    <div className="user-property-flex">
      <div className="user-property-text">
        <h3>{props.title}</h3>
        <p className="subtext">{props.value}</p>
      </div>
      {show_button && <div className="user-property-edit"><MusioEditPencil onClick={on_edit}/></div>}
    </div>
  );
};

export const UserDetails = (props) => {
  const email = useSelector(state => state.email);
  const contactName = useSelector(state => state.contactName);

  return (
    <div className="user-info-container">
      <UserProperty title="Email" value={email} onEdit={props.showEditEmail}/> 
      <UserProperty title="Name" value={contactName} onEdit={props.showEditName}/>
      <UserProperty title="Password" value="********" onEdit={props.showEditPassword}/>
    </div>
  );
}

export const EditEmail = (props) => {
  const [loading, setLoading] = useState(false);
  const [emailError, setEmailError] = useState('');
  const [newEmail, setNewEmail] = useState('');


  const onSubmit = (e)=>{
      e.preventDefault();

      if (newEmail == '') {
        setEmailError('Please fill out all fields')
        return;
      }

      //api call toc hange contact name
      setLoading(true);
        axios.post('/api/v1/user/email', {email: newEmail})
      .then(resp=>{
        setLoading(false);
        props.onClose();
        fetchUserInfo();
      })
      .catch(err=>{
        setLoading(false);
      });
  };

  return (
    <form onSubmit={onSubmit} className='user-edit-form'>
      <h3>Change Email</h3><br/>
      <label>
          <div className='user-edit-title'>New Email Address</div>
          <input className='edit-user-info' placeholder='' onChange={(e)=>setNewEmail(e.target.value)} type="email"/>
          <p className="subtext">If the email is available, you'll recieve a notification in your inbox</p>
      </label>
      <ErrorMessage text={emailError}></ErrorMessage>
      <div>
          <MusioButton loading={loading} size="small" buttonType="squared" text="Submit" type="submit"/>&nbsp;&nbsp;
          <MusioButton size="small" background="grey" buttonType="squared" text="Go Back" onClick={props.onClose}/>
      </div>

    </form>
  )
};

export const EditName = (props) => {
  const [loading, setLoading] = useState(false);
  const [nameError, setNameError] = useState('');
  const [contactName, setContactName] = useState('');

  const onSubmit = (e)=>{
    e.preventDefault();

    if (contactName == '') {
      setNameError('Please fill out all fields')
      return;
    }

    //api call toc hange contact name
    axios.post('/api/v1/user', {contactName})
    .then(resp=>{
      props.onClose();
      fetchUserInfo();
    });
  };

  return (
    <form onSubmit={onSubmit} className='user-edit-form'>
      <h3>Change Contact Name</h3><br/>
      <label>
        <div className='user-edit-title'>New Contact Name</div>
        <input className='edit-user-info' placeholder='' onChange={(e)=>setContactName(e.target.value)}/>
      </label>
      <ErrorMessage text={nameError}></ErrorMessage>
      <div>
        <MusioButton loading={loading} size="small" background="grey" buttonType="squared" text="Go Back" onClick={props.onClose}/>&nbsp;&nbsp;
        <MusioButton loading={loading} size="small" buttonType="squared" text="Submit" type="submit"/>
      </div>
    </form>
  )
  };

  export const EditPassword = (props) => {
  const [currentPassword, setCurrentPassword] = useState('');
  const [newPassword, setNewPassword] = useState('');
  const [confirmNewPassword, setConfirmNewPassword] = useState('');
  const [passwordError, setPasswordError] = useState('');
  const [loading, setLoading] = useState(false);

  const onSubmit = (e)=>{
    e.preventDefault();

    setPasswordError('');
    if (currentPassword === '' || newPassword === '' || confirmNewPassword ==='') {
      setPasswordError('Please fill out all fields');
      return;
    } else if (currentPassword === newPassword || currentPassword === confirmNewPassword) {
      setPasswordError(<span>New password cannot be the same <br/>as the current one</span>);
      return;
    } else if (newPassword !== confirmNewPassword) {
      setPasswordError('New passwords need to match');
      return;
    }

    setLoading(true);
    axios.post('/api/v1/user/password', {password: currentPassword, newPassword: newPassword})
    .then(resp=>{
    setLoading(false);
    if (resp.data.success) {
        props.onClose();
    }
    //there is a problem if the current pw you put is incorrect
    }).catch(err => {
    setLoading(false);
    let status = err.response.status;
    if (status == 422) {
      let constraint = err.response.data.error.passwordConstraint;
      setPasswordError(constraint);
    } else {
      setPasswordError('Current password incorrect!');
    }
    });
    
  };

  return (
    <form onSubmit={onSubmit} className='user-edit-form'>
      <h3>Change Password</h3><br/>
      <label>
        <div className='user-edit-title'>Current Password</div>
        <input className='edit-user-info' type='password' placeholder='' onChange={(e)=>setCurrentPassword(e.target.value)}/>
      </label>
      <br/>
      <label>
        <div className='user-edit-title'>New Password</div>
        <input className='edit-user-info' type='password' placeholder='' onChange={(e)=>setNewPassword(e.target.value)}/>
      </label>
      <label>
        <div className='user-edit-title'>Confirm New Password</div>
        <input className='edit-user-info' type='password' placeholder='' onChange={(e)=>setConfirmNewPassword(e.target.value)}/>
      </label>
      <ErrorMessage text={passwordError}></ErrorMessage>
      <div>
          <MusioButton loading={loading} size="small" background="grey" buttonType="squared" text="Go Back" onClick={props.onClose}/>&nbsp;&nbsp;
          <MusioButton loading={loading} size="small" buttonType="squared" text="Submit" type="submit"/>
      </div>
    </form>
  )
};