import axios from 'axios';
import {React, useState, useEffect} from 'react';
import { Link} from 'react-router-dom'

import { initializePaddle, Paddle } from '@paddle/paddle-js';

import './style.css';
import LoadingRing from 'components/LoadingRing'
import MusioLogo from 'components/MusioLogo';
import ErrorMessage from 'components/ErrorMessage';
import MusioButton from 'components/MusioButton';


const Migrate = (props) => {
    const [error, setError] = useState(null);
    const [token, setToken] = useState(null);
    const [canceled, setCanceled] = useState(false);
    const [tokenValid, setTokenValid] = useState(null);
    const [completed, setCompleted] = useState(false);
    const [paddle, setPaddle] = useState(null);
    const [buttonLoading, setButtonLoading] = useState(false);
    const [transactionId, setTransactionId] = useState(null);
    const [closed, setClosed] = useState(false);


    const validateToken = (token) => {
        return axios.get('/api/v1/migrate', { params: {token} })
    }

    const postToken = (token) => {
        return axios.post('/api/v1/migrate', null, { params: {token} })
    }

    const setupPaddle = () => {
        initializePaddle({
            environment: process.env.REACT_APP_PADDLE_ENVIRONMENT,
            token: process.env.REACT_APP_PADDLE_CLIENT_KEY,
            eventCallback: (eventData) => {
                if (eventData.name === "checkout.completed") {
                    setCompleted(true)
                }
                else if (eventData.name === "checkout.closed") {
                    setClosed(true)
                }
            }
        })
        .then((paddleInstance) => {
            if (paddleInstance) {
                setPaddle(paddleInstance);
            }
        });
    }

    const startMigration = (e) => {
        e.preventDefault();
        setButtonLoading(true);
        postToken(token).then(resp => {
            setTransactionId(resp.data.transactionId);
            const settings = {
                showAddDiscounts: false
            };
            paddle?.Checkout.open({settings, transactionId: resp.data.transactionId});
        });
    }
  
    useEffect( () => { // mount
      //extract params
      const search = window.location.search;
      const params = new URLSearchParams(search);
      const t = params.get('token');
      setToken(t)
      validateToken(t).then(resp => {
        if (resp.data.canceled == true) {
            setCanceled(true);
        }
        setTokenValid(true);
        setupPaddle();
        
        
      }).catch(error => {
        console.log(error);
        setTokenValid(false);
      });
      
    }, [] );
    
  
    return (
      <div className='onboarding-container'>
        <MusioLogo />
        <div className="onboarding-spacer" />
  
        <h1 className='onboarding-header'>Migrating from Stripe to Paddle</h1>
        <ErrorMessage text={error} />

        {tokenValid && !canceled && !transactionId && 
        <div>
            <p>The migration process involves a overlay form provided by Paddle. Please enter your most up to date payment information or choose a new payment option (<span className="subtext">credit card, PayPal, Apple Pay, Google Pay, iDEAL, Bancontact</span>). <br/><br/>No money will be charged until your next subscription renewal date.</p><br />
            <MusioButton
                loading={buttonLoading}
                size="small"
                text="Start Migration"
                onClick={startMigration}
                />
        </div>
        }


        {tokenValid == false &&
        <div>
            <p>The link is no longer valid! You may close this window</p>
        </div>
        }

        {tokenValid && canceled && 
        <div>
            <p>Your subscription has elapsed or was canceled recently.<br/>Subscription migration is no longer required. You may close this window</p>
        </div>
        }

        {completed && closed &&
        <div>
            <p>Thank you for completing the subscription migration! An email confirmation will be sent to your inbox soon. You may close this window</p>
        </div>
        }
        {!completed && closed &&
        <div>
            <p>The migration process did not complete. Please refresh the page</p>
        </div>
        }
      </div>
    );
  };
  
  export default Migrate;