import React from 'react';
import './style.css'

const MusioCheckbox = (props) => {
  return (
    <div className="checkbox-div">
      <input className='checkbox-hidden' type="checkbox" checked={props.checked} onChange={(e)=>{}}/>
      <span className="musio-checkmark" />
    </div>
  );
};
  
export default MusioCheckbox;