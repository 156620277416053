import axios from 'axios';
import React from 'react';
import {Link} from 'react-router-dom'

import './style.css';
import MusioLogo from 'components/MusioLogo';
import ErrorMessage from 'components/ErrorMessage';
import MusioButton from 'components/MusioButton';

class RecoverReset extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      newPassword:'',
      confirmNewPassword:'',
      resetPasswordSubmitted: false,
      resetPasswordError:'',
      token: '',
      isLoading:false
    };
    this.resetPasswordClicked = this.resetPasswordClicked.bind(this)
  };

  componentDidMount() {
    //extract params
    const search = window.location.search;
    const params = new URLSearchParams(search);
    const token = params.get('token');
  
    //GET to api/v1/verify?token=731479119...
    axios.get('/api/v1/reset', {params: {token}})
    .then(resp => {
      if (resp.data.success) {
        this.setState({token: token});
      } else if (resp.data.error) {

         window.location.href='/error';
        
      } else {
        console.assert(false, "HOW!?");
      }
    });
  };

  resetPasswordClicked(e) {
    this.setState({isLoading:true, resetPasswordError:''})
    e.preventDefault();

    //check if new pw are ok
    if (this.state.newPassword === '') {
      this.setState({resetPasswordError:'Please fill out your new password', isLoading:false});
      return;
    }
    else if (this.state.confirmNewPassword === '') {
      this.setState({resetPasswordError:'Please confirm the new password',isLoading:false});
      return;
    } else if (this.state.newPassword !== this.state.confirmNewPassword){
      this.setState({resetPasswordError:'Passwords do not match',isLoading:false});
      return;
    }

    //if the pw passes our initial checks, continue to submit 
    axios.post('/api/v1/reset', {
      token: this.state.token,
      password: this.state.newPassword
    })
    .then(resp => {
      if (resp.data.success) {
        //if it comes back ok, set it complete
        this.setState({resetPasswordSubmitted: true, isLoading:false})
      }
    })
    .catch(err=> {
      let status = err.response.status
      if (status == 422) {
        let constraint = err.response.data.error.passwordConstraint
          this.setState({resetPasswordError: constraint, isLoading:false})
      } else {
        this.setState({resetPasswordError:'Token is Invalid',isLoading:false})
      }
    });

  };

  renderResetForm() {
    return (
      <div className='onboarding-container'>
        <MusioLogo />
        <div className="onboarding-spacer" />

        <ErrorMessage text={this.state.resetPasswordError} />
        <h1 className='onboarding-header'>reset password.</h1>
        <form onSubmit={this.resetPasswordClicked} className='onboarding-form'>
          <input className='input' type='password' placeholder=' new password...' onChange={(e)=>this.setState({newPassword: e.target.value})}/>
          <input className='input' type='password' placeholder=' confirm new password...' onChange={(e)=>this.setState({confirmNewPassword: e.target.value})}/>
          <div className="onboarding-spacer" />
          
          <div><MusioButton text="submit." /></div>
        </form>
      </div>
    );
  }

  render() {
    if (this.state.resetPasswordSubmitted) {
      return (
        <div className='onboarding-container'>
          <MusioLogo />
          <div className="onboarding-spacer" />

          <h1 className='onboarding-header'>password changed successfully!</h1>
          <div className="onboarding-spacer" />

          <Link to="/login">
            <MusioButton text="log in" />
          </Link>
        </div>
      )
    }

    return this.renderResetForm()
  };

};

export default RecoverReset;
