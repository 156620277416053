import axios from 'axios';
import React from 'react';

import LoadingRing from 'components/LoadingRing'
import ErrorMessage from 'components/ErrorMessage';
import MusioLogo from 'components/MusioLogo';

import './style.css';
import MusioButton from 'components/MusioButton';


class Recover extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      recoverEmail: '',
      recoverSubmitted: false,
      recoverError: '',
      isLoading: false
    };
    this.recoverClicked = this.recoverClicked.bind(this)
  };

  componentDidMount(){
  }

  recoverClicked(e) {
    this.setState({isLoading:true})
    e.preventDefault();
    if (this.state.recoverEmail === '') {
      this.setState({recoverError:'please fill out your email', isLoading:false})
      return;
    }

    axios.post('/api/v1/recover', {
      authId: this.state.recoverEmail,
    })
    .then(resp => {
      if (resp.data.success) {
        this.setState({recoverSubmitted: true, isLoading:false});
      }
    });
  };

  render(){
    //RECOVERY SUBMITTED
    if (this.state.recoverSubmitted) {
      return(
        <div className='onboarding-container'>
          <MusioLogo />
          <div className="onboarding-spacer" />

          <h1 className="onboarding-header">account recovery started!</h1>

          <p className="subtext">a reset link has been sent to your email account</p>
        </div>
      )
    }

    return(
      <div className='onboarding-container'>
        <MusioLogo />
        <div className="onboarding-spacer" />

        <ErrorMessage text={this.state.recoverError} />

        <h1 className='onboarding-header'>recover account.</h1>
        <div className="onboarding-spacer" />
          
        <form onSubmit={this.recoverClicked} className='onboarding-form'>
          <input className='input' id='input-email' type='email' placeholder=' email...' onChange={(e)=>this.setState({recoverEmail: e.target.value})}/>
          <div><MusioButton text="submit." /></div>
        </form>
      </div>
    )
  };
  
};

export default Recover;
